import { RouteRecordRaw } from 'vue-router';

import Home from '@/views/Home.vue';
import LandingPage from '@/views/LandingPage.vue';
// import LandingPage from '@/views/feature-kol/LandingPage.vue';

function loadComponent(filePath: string, index = false) {
  return () => import(`../views/${filePath}${index ? '/index' : ''}.vue`);
}

// Record<string, RouteRecordRaw>
export const RouteRecord = {
  Home: {
    name: 'Home',
    path: '/home',
    component: Home,
    meta: {
      auth: true,
      menu: true,
      label: '首頁',
    },
  },
  Login: {
    name: 'Login',
    path: '/login',
    component: loadComponent('Login'),
    meta: {
      auth: false,
      label: '登入',
    },
  },
  SignUp: {
    name: 'SignUp',
    path: '/sign-up',
    component: loadComponent('SignUp', true),
    meta: {
      auth: false,
      label: '加入RE推廣平台',
    },
  },
  SignUpForm: {
    name: 'SignUpForm',
    path: 'form',
    component: loadComponent('SignUp/Form'),
    meta: {
      auth: false,
    },
  },
  SignUpSms: {
    name: 'SignUpSms',
    path: 'sms',
    component: loadComponent('SignUp/Sms'),
    meta: {
      label: 'RE 紅包會員登入',
      auth: false,
    },
  },
  SignUpDone: {
    name: 'SignUpDone',
    path: 'done',
    component: loadComponent('SignUp/Done'),
  },
  Recommend: {
    name: 'Recommend',
    path: '/recommend',
    component: loadComponent('Recommend'),
    meta: {
      label: '推薦人數',
      auth: true,
      menu: true,
    },
  },
  Feedback: {
    name: 'Feedback',
    path: '/feedback',
    component: loadComponent('Feedback'),
    meta: {
      label: '回饋紅包金',
      auth: true,
      menu: true,
    },
  },
  Download: {
    name: 'Download',
    path: '/download',
    component: loadComponent('Download', true),
    meta: {
      label: '下載專區',
      auth: true,
      menu: true,
    },
  },
  DownloadDetails: {
    name: 'DownloadDetails',
    path: '/download/:type',
    component: loadComponent('Download/Details'),
    meta: {
      label: '下載專區',
      auth: true,
      menu: false,
    },
  },
  DailyBonus: {
    name: 'DailyBonus',
    path: '/daily-bonus',
    component: loadComponent('DailyBonus'),
    meta: {
      label: '每日簽到',
      auth: true,
      menu: true,
    },
  },
  Reward: {
    name: 'Reward',
    path: '/reward',
    component: loadComponent('Reward'),
    meta: {
      label: '獎勵領取',
      auth: true,
      menu: true,
    },
  },
  LandingPage: {
    name: 'LandingPage',
    path: '/',
    component: LandingPage,
    meta: {
      label: '外送員 我挺你！',
      auth: false,
      menu: false,
    },
  },
};
